﻿$('.maintenance-banner').on('closed.bs.alert', function () {
    $('.page').removeClass('banner_adjusted');
})

$(document).ready(function () {
    if ($('.maintenance-banner').length > 0) {
        $('.page').addClass('banner_adjusted');
    }

    const $container = $('.text-container');
    const $banner = $('.maintenance-banner');
    const $bannerLink = $('.dismiss-link');

    function checkMultiline() {
        const originalHeight = $container.height();
        $container.css('white-space', 'nowrap');
        const singleLineHeight = $container.height();
        $container.css('white-space', '');

        if (originalHeight > singleLineHeight) {
            $banner.addClass('extra-padding');
            $bannerLink.addClass('link-padding');
        } else {
            $banner.removeClass('extra-padding');
            $bannerLink.removeClass('link-padding');
        }
    }

    $(window).resize(checkMultiline);
    checkMultiline();  // Initial check
});